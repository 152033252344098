// Libraries
import {useMemo, useState} from 'react';
// Components
import Search from 'components/Search';
import Container from 'components/Container';
import Title, {TitleTag, TitleVariant} from 'components/typography/Title';
import Info from 'components/Info';
import List from 'components/List';
// Types
import {Tag} from 'types/tags';

const Home = () => {
	const [currentView, setCurrentView] = useState('chat');

	const isSearch = useMemo(() => currentView === 'search', [currentView]);

	return (
		<Container as={Tag.Section} classes='home-search'>
			<div className='home-search__contetnt'>
				<div className='home-search__select'>
				<button
						data-is-active={!isSearch}
						className='home-search__button'
						onClick={() => setCurrentView('chat')}>
						<span>Ask & Chat</span>
					</button>
					<button
						data-is-active={isSearch}
						className='home-search__button'
						onClick={() => setCurrentView('search')}>
						<span>Quick Search</span>
					</button>
				</div>
				<div className='home-search__form'>
					<Title
						tag={TitleTag.h2}
						variant={TitleVariant.main}
						classes='home-search__form-title'>
						{isSearch ? 'Search knowledge base' : 'Ask me anything...'}
						<Info>
							{!isSearch ? (
								<List
									items={[
										<>
											<strong>Examples:</strong> Start by asking me a question.
										</>,
										<>
											<strong>Capability:</strong> kWiki has the ability to process
											in-depth knowledge of Australian Standards including graphics and
											tables. Make sure you ask follow up questions and for a deeper
											explanations.
										</>,
										<>
											<strong>Limitations:</strong> kWiki can make mistakes. Consider
											verifying sources and accuracy.
										</>,
										<>
											<strong>Feedback:</strong> If I don't provide the answer you are
											looking for, let me know so I can improve.
										</>,
									]}
								/>
							) : (
								<List
									items={[
										<>
											<strong>Examples:</strong> Search using keywords or phrases for
											topics, FAQs, guides, product details, and tutorials.
										</>,
										<>
											<strong>Feedback:</strong> If you can't find what you need, let us
											know. We regularly update our knowledge base.
										</>,
									]}
								/>
							)}
						</Info>
					</Title>
					<Search isChat={!isSearch} />
				</div>
			</div>
		</Container>
	);
};

export default Home;
