import {Store} from 'redux/ReduxStore';

export const getAuth = (state: Store) => state.auth;

export const getUsername = (state: Store) => state.auth.user?.username;

export const getUser = (state: Store) => state.auth.user;

export const getUserToken = (state: Store) => state.auth?.tokenId;

export const getState = (state: Store) => state.auth.state;

export const getPersona = (state: Store) => state.auth.persona;

export const getDefaultState = (state: Store) => state.auth.defaultState;

export const getDefaultPersona = (state: Store) => state.auth.defaultPersona;
