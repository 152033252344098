// Libraries
import axios from 'axios';
import {toast} from 'react-toastify';
// Store
import {States} from 'redux/ReduxStore';
// Models
import {Document} from 'models/search';
import {config} from 'config/configLoader';

export const getDocument = async (
	message: string,
	userToken: string,
	state: States
): Promise<Document[]> => {
	const response = await axios.post<Document[]>(
		`${config.api.link}/search`,
		{
			message,
			state,
		},
		{
			headers: {Authorization: `Bearer ${userToken}`},
		}
	);

	if (`${response.request.status}`[0] !== '2') {
		toast.error(response?.statusText);
	}

	return response.data;
};
