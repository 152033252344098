// Libraries
import {useMemo, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
// Components
import Container from 'components/Container';
import Title, {TitleTag, TitleVariant} from 'components/typography/Title';
import Button, {ButtonTag, ButtonVariant} from 'components/Button';
import Modal from 'components/Modal';
import FeedbackForm from 'components/forms/FeedbackForm';
// assets
import {ReactComponent as Logo} from '../../assets/icons/logo.svg';
import {ReactComponent as Logout} from '../../assets/icons/logout.svg';
import {ReactComponent as Settings} from '../../assets/icons/settings.svg';
// Selectors
// import {getIsLogged} from 'redux/selectors';
// Types
import {Tag} from 'types/tags';
import {clearUser, clearUserToken} from 'redux/actions/authActions';
import {useMsal} from '@azure/msal-react';
import {getUser} from 'redux/selectors';
import {b2cPolicies} from 'api/msal';

const TopBar = () => {
	const dispatch = useDispatch();
	const {pathname} = useLocation();
	const {instance} = useMsal();
	const user = useSelector(getUser);

	const [isOpenFeedback, setIsOpenFeedback] = useState(false);
	const isLogged = !!user;

	const logout = async () => {
		dispatch(clearUser());
		dispatch(clearUserToken());
		await instance.logoutRedirect();
	};

	const isChat = useMemo(() => pathname.includes('chat'), [pathname]);

	const editProfileHandler = async () => {
		await instance.loginRedirect({
			authority: b2cPolicies.authorities.editProfile.authority,
			scopes: ['openid', 'profile'], // Add the necessary scopes
		});
		dispatch(clearUserToken());
	};

	return (
		<>
			<Container as={Tag.Nav} classes='top-bar'>
				<Title tag={TitleTag.h1} variant={TitleVariant.main}>
					<Link to='/'>
						<Logo />
					</Link>
				</Title>
				{isLogged ? (
					<div className='top-bar__profile'>
						<Link className='top-bar__link' to={isChat ? '/' : '/chat'}>
							{isChat ? 'Quick Search' : 'Ask & Chat'}
						</Link>
						<button
							className='top-bar__link'
							onClick={() => {
								setIsOpenFeedback(true);
							}}>
							share feedback
						</button>
						<div className='top-bar__account'>
							<div className='top-bar__profile-picture'>
								{user.name?.slice(0,1)}
							</div>
							<p className='top-bar__profile-nick'>{user?.name}</p>
							<div className='top-bar__account-options'>
								<button
									className='top-bar__account-select'
									onClick={editProfileHandler}>
									<Settings />
									Profile settings
								</button>
								<button onClick={logout} className='top-bar__account-select'>
									<Logout />
									Log out
								</button>
							</div>
						</div>
					</div>
				) : (
					<div className='top-bar__login'>
						<Button
							as={ButtonTag.InternalLink}
							href='/register'
							variant={ButtonVariant.Outline}>
							Sign up
						</Button>
						<Button as={ButtonTag.InternalLink} href='/login'>
							Log in
						</Button>
					</div>
				)}
			</Container>
			{isOpenFeedback && (
				<Modal
					closeHandler={() => {
						setIsOpenFeedback(false);
					}}
					wrapperClasses='feedback__wrapper'>
					<FeedbackForm
						closeModalHandler={() => {
							setIsOpenFeedback(false);
						}}
					/>
				</Modal>
			)}
		</>
	);
};

export default TopBar;
