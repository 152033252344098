// Libraries
import axios from 'axios';
import {toast} from 'react-toastify';
// Store
import {store} from 'redux/ReduxStore';
import {setChats} from 'redux/actions/chatActions';
// Models
import {ChatModel, MessageModel, StreamDoc} from 'models/chat';
import {Document} from 'models/search';
import {Guid} from 'js-guid';
import {config} from 'config/configLoader';

const getAllSessions = async (userToken: string): Promise<ChatModel[]> => {
	const response = await axios.get<ChatModel[]>(`${config.api.link}/chat/`, {
		headers: {Authorization: `Bearer ${userToken}`},
	});

	if (`${response.request.status}`[0] !== '2') {
		toast.error(response?.statusText);
	}

	store.dispatch(setChats(response.data));

	return response.data;
};

const createSession = async (userToken: string) => {
	const response = await axios.post(
		`${config.api.link}/chat/`,
		{},
		{headers: {Authorization: `Bearer ${userToken}`}}
	);

	if (`${response.request.status}`[0] !== '2') {
		toast.error(response?.statusText);
	}

	return response.data;
};

const deleteSession = async (sessionId: string, userToken: string) => {
	const response = await axios.delete(`${config.api.link}/chat/${sessionId}`, {
		headers: {Authorization: `Bearer ${userToken}`},
	});

	if (`${response.request.status}`[0] !== '2') {
		toast.error(response?.statusText);
	}

	return response.data;
};

const sendMessage = async (
	sessionId: string,
	message: string,
	userToken: string
) => {
	const state = store.getState();
	const userState = state.auth?.state;
	const preference = state.auth?.persona;

	const response = await axios.post(
		`${config.api.link}/chat/${sessionId}/message`,
		{
			message,
			state: userState,
			preference,
		},
		{headers: {Authorization: `Bearer ${userToken}`}}
	);

	if (`${response.request.status}`[0] !== '2') {
		toast.error(response?.statusText);
	}

	return response.data;
};

const getMessage = async (
	sessionId: string,
	userToken: string
): Promise<MessageModel[]> => {
	const response = await axios.get<MessageModel[]>(
		`${config.api.link}/chat/${sessionId}/message`,
		{headers: {Authorization: `Bearer ${userToken}`}}
	);

	if (`${response.request.status}`[0] !== '2') {
		toast.error(response?.statusText);
	}

	return response.data;
};

const streamMessage = async (
	sessionId: string,
	messageId: Guid,
	message: string,
	userToken: string
) => {
	const state = store.getState();
	const userState = state.auth?.state;
	const preference = state.auth?.persona;

	try {
		const response = await fetch(
			`${config.api.link}/chat/${sessionId}/message/${messageId}/stream`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${userToken}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					message,
					state: userState,
					preference,
				}),
			}
		);

		if (!response.ok) {
			throw new Error(`Request failed with status ${response.status}`);
		}

		const reader = response.body?.getReader();

		const stream = new ReadableStream({
			start(controller) {
				function push() {
					reader &&
						reader.read().then(({done, value}) => {
							if (done) {
								controller.close();
								return;
							}
							controller.enqueue(value);
							push();
						});
				}
				push();
			},
		});

		const streamReader = stream.getReader();

		return {response, streamReader};
	} catch (error) {
		// Handle errors
		console.error(error);
		toast.error('An error occurred');
	}
};

const getDocuments = async (
	sessionId: string,
	messageId: Guid,
	userToken: string
) => {
	const response = await axios.get<StreamDoc[]>(
		`${config.api.link}/chat/${sessionId}/message/${messageId}/attachments`,
		{headers: {Authorization: `Bearer ${userToken}`}}
	);

	if (`${response.request.status}`[0] !== '2') {
		toast.error(response?.statusText);
	}

	return response.data;
};

export {
	createSession,
	getAllSessions,
	deleteSession,
	sendMessage,
	getMessage,
	streamMessage,
	getDocuments,
};
