import Markdown from 'react-markdown';
// Components
import {Text, Title} from './typography';
import {TitleTag, TitleVariant} from './typography/Title';
import Button, {ButtonVariant, ButtonTag} from './Button';
// Assets
import {ReactComponent as DocumentSVG} from '../assets/icons/document.svg';
import {ReactComponent as Magnifier} from '../assets/icons/search-icon.svg';
// Types
import {Document} from 'models/search';

export interface DocumentCardPrps extends Document {
	isPreview?: boolean;
}

const DocumentCard = (props: DocumentCardPrps) => {
	const {isPreview = false} = props;

	return (
		<div className='document-card'>
			<Title
				classes='document-card__title'
				tag={TitleTag.h3}
				variant={TitleVariant.sectionSubtitle}>
				<span>
					<DocumentSVG />
				</span>
				{props.metadata.reference}
			</Title>
			<div className='document-card__info'>
				<Text>
					State: <span>{props.metadata.stateRelevancy}</span>
				</Text>
				<Text>
					Document type: <span>{props.metadata.documentType}</span>
				</Text>
				{!!props.metadata.score && <Text>
					Score: <span>{Math.round(+props.metadata.score * 10000) / 100}%</span>
				</Text>}
			</div>
			<div className='document-card__content'>
				<Text>
					<span>Relevant section</span>
				</Text>
				<div className='document-card__description'>
					<Markdown>{props.page_content}</Markdown>
				</div>
			</div>
			<div className='document-card__buttons'>
				{isPreview && (
					<Button
						variant={ButtonVariant.Outline}
						as={ButtonTag.InternalLink}
						href={`/preview/${props.metadata.id}`}>
						<Magnifier /> View
					</Button>
				)}
			</div>
		</div>
	);
};

export default DocumentCard;
