// Action types
import {DocumentsActions} from '../actions/documentsActions';
// Types
import {Document} from 'models/search';

const documentsReducer = (
	state: Document[] | null = null,
	action: {type: DocumentsActions; payload: Document[]}
) => {
	switch (action.type) {
		case DocumentsActions.SET_ALL_DOCUMENTS:
			return action.payload;
		default:
			return state;
	}
};

export default documentsReducer;
