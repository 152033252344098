import {cutText} from './cutText';

export const getQueryFilters = () => {
	const filtersString = cutText(
		window.location.search,
		'?filters=',
		true
	).replace('?filters=', '');

	let filters: any = {};

	filtersString.split('&').map((filter) => {
		const indexOfValues = filter.indexOf('=');
		const key = filter.slice(0, indexOfValues) as string;
		if (key === '?search') return;
		filters[key] = {};
		filter
			.slice(indexOfValues + 1)
			.replaceAll('+', ' ')
			.split('%2C')
			.map((value) => (filters[key][`${value}`] = true));
	});

	return filters;
};
