import React from 'react';

interface ListProps {
	items: React.ReactNode[];
}

const List = (props: ListProps) => {
	const {items} = props;

	return (
		<ul className='custom-list'>
			{items.map((item, index) => (
				<li key={index}><span>{item}</span></li>
			))}
		</ul>
	);
};

export default List;
