// Libraries
import {useEffect, useState} from 'react';
// Components
import Title, {TitleTag, TitleVariant} from 'components/typography/Title';
import Checkbox from './Checkbox';
import Button from 'components/Button';
// API
// import { getFilters } from 'api';

interface Filter {
	name: string;
	results: number;
}

interface FilterCategory {
	text: string;
	name: string;
	options: Set<string>;
}

export interface FiltersProps {
	filters: FilterCategory[];
	currentFilters: any;
	setCurrentFilters: any;
}

const Filters = (props: FiltersProps) => {
	const {filters, currentFilters, setCurrentFilters} = props;

	return (
		<div className='filters'>
			{filters.map(({name, text, options}) => {
				const filterOptions = [...options];
				const filter = name.replace(':', '');

				if (filterOptions.length === 0) return null;

				return (
					<div key={name} className='filters__group'>
						<Title tag={TitleTag.h4} variant={TitleVariant.standard}>
							{text}
						</Title>
						<div className='filters__group-options'>
							{filterOptions.map((filterName) => (
								<Checkbox
									key={filterName}
									id={filterName}
									name={filterName}
									value={!!currentFilters?.[filter]?.[filterName]}
									valueChangeHandler={() =>
										setCurrentFilters({
											...currentFilters,
											[filter]: {
												...currentFilters[filter],
												[filterName]: !!!currentFilters?.[filter]?.[filterName],
											},
										})
									}
									label={filterName}
								/>
							))}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default Filters;
