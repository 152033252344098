import axios from 'axios';
import { config } from 'config/configLoader';

export const getSearch = (searchValue: string, userToken: string) => {
	return axios.get(
		`https://${config.api.service}.search.windows.net/indexes/${config.api.indexName}/docs?api-version=2023-07-01-Preview&search=${searchValue}`,
		{
			headers: {
				Authorization: `Bearer ${userToken}`,
				'Content-Type': 'application/json',
				'api-key': config.api.key,
			},
		}
	);
};

export const postIdSearch = (id: string, userToken: string) => {
	return axios.post(
		`https://${config.api.service}.search.windows.net/indexes/${config.api.indexName}/docs/search?api-version=2023-07-01-Preview`,
		{filter: `'${id}' eq id`, select: 'content, documentType, stateRelevancy, name'},
		{
			headers: {
				Authorization: `Bearer ${userToken}`,
				'Content-Type': 'application/json',
				'api-key': config.api.key,
			},
		}
	);
};
