// Libraries
import React from 'react';
import classNames from 'classnames';
// Types
import {ReactNode} from 'react';
// Exported types
export enum InfoVaiant {
	Gray = 'gray',
	Orange = 'orange',
}
export interface InfoProps {
	variant?: InfoVaiant;
	children: ReactNode;
	hoverItem?: ReactNode;
	classes?: string;
}

const Info = (props: InfoProps) => {
	const {children, variant = InfoVaiant.Gray, classes, hoverItem} = props;

	return (
		<div className={classNames('info', classes)} data-variant={variant}>
			{hoverItem ? (
				hoverItem
			) : (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='20'
					height='20'
					viewBox='0 0 20 20'
					fill='none'>
					<circle cx='9.10388' cy='9.10455' r='7.44781' fill='white' />
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.5332 0C4.27683 0 0 4.27683 0 9.5332C0 14.7896 4.27683 19.0664 9.5332 19.0664C14.7896 19.0664 19.0664 14.7896 19.0664 9.5332C19.0664 4.27683 14.7896 0 9.5332 0ZM10.7252 4.17C11.3836 4.17 11.9169 4.70326 11.9169 5.36165C11.9169 6.02004 11.3836 6.5533 10.7252 6.5533C10.0668 6.5533 9.53356 6.02004 9.53356 5.36165C9.53356 4.70326 10.0668 4.17 10.7252 4.17ZM11.5958 14.3983C11.6539 14.3754 11.7143 14.3515 11.7774 14.3267L11.9341 13.6868C11.4038 13.937 10.2378 14.1849 10.2378 13.1821C10.2378 12.9217 10.3504 12.3837 10.4231 12.1305C10.5523 11.6729 10.6564 11.3073 10.7404 11.0119C11.0795 9.82034 11.0934 9.77145 11.1249 9.43675C11.1458 9.20914 11.1565 9.05065 11.1565 8.96068C11.1565 7.99902 10.4111 7.48959 9.39229 7.48959C8.71483 7.48959 7.9212 7.8024 7.2932 8.05801L7.13649 8.69733C7.57145 8.53526 8.31921 8.34162 8.64036 8.64787C8.96151 8.95413 8.73211 9.82701 8.61891 10.2542L8.03261 12.3295C7.83778 13.1398 7.73291 13.9311 8.35555 14.4792C8.67074 14.7563 9.11285 14.8951 9.68126 14.8951C10.3367 14.8951 10.6555 14.7693 11.5958 14.3983Z'
						fill='#E1E1E1'
					/>
				</svg>
			)}
			<span className='info__text'>{children}</span>
		</div>
	);
};

export default Info;
