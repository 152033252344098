// Libraries
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
// Redux store
import {store, persistor} from './ReduxStore';

const ReduxWrapper = ({children}: {children: React.ReactNode}) => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				{children}
			</PersistGate>
		</Provider>
	);
};

export default ReduxWrapper;
