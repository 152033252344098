export enum FiltersActions {
  SET_ALL_FILTERS = 'SET_ALL_FILTERS',
  SET_SEARCH_PHRASE = 'SET_SEARCH_PHRASE',
}

export const setFilters = (posts: FiltersActions[]) => ({
  type: FiltersActions.SET_ALL_FILTERS,
  payload: posts
})

export const setSearchPhrase = (posts: string) => ({
  type: FiltersActions.SET_SEARCH_PHRASE,
  payload: posts
})