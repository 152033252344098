// Action types
import {AuthActions} from 'redux/actions/authActions';
import {AuthState, States, Personas} from 'redux/ReduxStore';

const chatReducer = (
	state: AuthState = {
		user: undefined,
		defaultState: States.Victoria,
		defaultPersona: Personas.Electricians,
		state: States.Victoria,
		persona: Personas.Electricians,
		tokenId: undefined,
	},
	action: {type: AuthActions; payload: any}
): AuthState => {
	switch (action.type) {
		case AuthActions.SET_STATE:
			return {...state, state: action.payload ?? States.Victoria};
		case AuthActions.SET_DEFAULT_STATE:
			return {
				...state,
				defaultState: action.payload ?? States.Victoria,
				state: action.payload ?? States.Victoria,
			};
		case AuthActions.SET_PERSONA:
			return {...state, persona: action.payload ?? Personas.Electricians};
		case AuthActions.SET_DEFAULT_PERSONA:
			return {
				...state,
				defaultPersona: action.payload ?? Personas.Electricians,
				persona: action.payload ?? Personas.Electricians,
			};
		case AuthActions.SET_USER:
			return {...state, user: action.payload};
		case AuthActions.SET_USER_TOKEN:
			return {...state, tokenId: action.payload};
		default:
			return state;
	}
};

export default chatReducer;
