// Libraries
import {FormEvent, useState} from 'react';
// Components
import {Title} from 'components/typography';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';
import AddFile from 'components/AddFile';
// Types
import {TitleTag, TitleVariant} from 'components/typography/Title';
import {toast} from 'react-toastify';
import { useSelector } from 'react-redux';
import { getUsername } from 'redux/selectors/auth';
import { config } from 'config/configLoader';

const values = [
	{value: 'bug', label: 'Bug/error'},
	{value: 'existing-feature', label: 'Existing feature improvement'},
	{value: 'new-feature', label: 'New feature request/suggestion'},
	{value: 'other', label: 'Other/General feedback'},
];

const INITIAL_STATE = {
	type: {value: values[0], errorMessage: undefined, isValid: true},
	message: {value: '', errorMessage: undefined, isValid: undefined},
	// file: {value: '', errorMessage: undefined, isValid: true},
};

type FormAttributes = 'type' | 'message';
export enum Steps {
	Initial = 'initial',
	Sending = 'loading',
	Success = 'success',
	Error = 'error',
}
const FeedbackForm = (props: {closeModalHandler: () => void}) => {
	const {closeModalHandler} = props;

	const [sent, setSent] = useState(false);
	const [currentState, setCurrentState] = useState(INITIAL_STATE);

	const upadateStateHandler = (name: string, value: any) => {
		setCurrentState({...currentState, [name]: value});
	};

	const username = useSelector(getUsername)

	const submitHandler = (event: FormEvent) => {
		event.preventDefault();
		const stateKeys = Object.keys(currentState) as FormAttributes[];
		const isError = stateKeys.some(
			(key: FormAttributes) =>
				currentState[key].isValid === undefined ||
				currentState[key].isValid === false
		);

		if (isError) return toast.warning('Correct the data in the form');

		let data: any = {username};

		stateKeys.forEach(
			(key: FormAttributes) =>
				(data[key] =
					key === 'type' ? currentState[key].value.label : currentState[key].value)
		);

		toast.info('Sending message');

		fetch(`https://formspree.io/f/${config.formspreeId}`, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				Accept: 'application/json',
			},
		})
			.then((response) => {
				if (response.ok) {
					setSent(true);
					setCurrentState(INITIAL_STATE);
				} else {
					toast.error('E-mail sending failed');
				}
			})
			.catch((error) => {
				toast.error('E-mail sending failed');
			});
	};

	return (
		<form onSubmit={submitHandler} className='feedback-form'>
			{!sent ? (
				<>
					<Title
						tag={TitleTag.h2}
						variant={TitleVariant.main}
						classes='feedback-form__title'>
						Feedback form
					</Title>
					<Select
						options={values}
						value={currentState.type.value}
						valueChangeHandler={upadateStateHandler}
						name='type'
						id='type'
						label='Type of feedback'
					/>
					<Input
						as='textarea'
						name='message'
						id='message'
						label='Description'
						required
						errorMessage={currentState.message.errorMessage}
						value={currentState.message.value}
						valueChangeHandler={upadateStateHandler}
					/>
					{/* <AddFile
						id='file'
						name='file'
						label={(currentState.file.value as any)?.name}
						labelAsLink={!!!(currentState.file.value as any)?.name}
						value={currentState.file.value}
						valueChangeHandler={upadateStateHandler}
					/> */}
					<Button classes='feedback-form__button' type='submit'>
						Send
					</Button>
				</>
			) : (
				<div className='feedback-form--success'>
					<div className='feedback-form__bg'>
						<Title tag={TitleTag.h2} variant={TitleVariant.main}>
							Thank you!
							<br />
							Your feedback has been received
						</Title>
					</div>
					<Button
						classes='feedback-form__button'
						type='button'
						onClick={closeModalHandler}>
						Close
					</Button>
				</div>
			)}
		</form>
	);
};

export default FeedbackForm;
