// Components
import TopBar from './TopBar';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = (props: {children: React.ReactNode}) => {
	const {children} = props;

	return (
		<>
			<TopBar />
			<div className='toast'>
				<ToastContainer
					position='top-right'
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					style={{paddingTop: 100}}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					pauseOnHover
					theme='light'
				/>
			</div>
			<main className='main-wrapper'>{children}</main>
		</>
	);
};

export default Layout;
