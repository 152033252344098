// Libraries
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
// Components
import Loader from './Loader/Loader';
// Icons
import {ReactComponent as Bin} from '../assets/icons/bin.svg';
// Actions
import {removeChat} from 'redux/actions/chatActions';
// Types
import {ChatModel} from 'models/chat';
import {deleteSession} from 'api/chat';
import {getUserToken} from 'redux/selectors';

const ChatListItem = (props: ChatModel) => {
	const {id, name, created_on} = props;
	const tokenId = useSelector(getUserToken);
	const location = useLocation();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();

	const deleteChat = async () => {
		if (location.pathname.replace('/chat/', '') === id) {
			navigate('/chat/');
		}
		setLoading(true);
		tokenId &&
			(await deleteSession(id, tokenId).then(() => {
				dispatch(removeChat(id));
				setLoading(false);
			}));
	};

	return (
		<li>
			<Link to={`/chat/${loading ? '' : id}`}>
				{name ??
					`Ask: ${(() => {
						const d = new Date(created_on);
						const utc = d.getTime() + -d.getTimezoneOffset() * 60000;
						const nd = new Date(utc);
						return nd.toLocaleString();
					})()}`}
			</Link>
			<button onClick={deleteChat} disabled={loading}>
				{loading ? <Loader /> : <Bin />}
			</button>
		</li>
	);
};

export default ChatListItem;
