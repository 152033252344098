// Libraries
import classNames from 'classnames';
// Types
import {Common} from 'types/main';
import { Tag } from 'types/tags';

interface ContentWraperProps extends Common{
  as?: Tag
}

const ContentWraper = (props: ContentWraperProps) => {
	const {id, classes, children, as = Tag.Div} = props;
  const TagComponent = as

	return (
		<TagComponent className={classNames('content-wrapper',classes)} id={id}>
			{children}
		</TagComponent>
	);
};

export default ContentWraper;
