// Libraries
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
// Types
import {ButtonHTMLAttributes, AnchorHTMLAttributes} from 'react';
import {Common} from '../types/main';

export enum ButtonTag {
	InternalLink = 'Link',
	ExternalLink = 'a',
	Button = 'button',
}

export enum ButtonVariant {
	Primary = 'primary',
	Outline = 'outline',
}

interface CommonProps extends Common {
	as?: ButtonTag;
	fullWidth?: boolean;
	href?: string;
	variant?: ButtonVariant;
	isDisabled?: boolean
}
type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

const Button: React.FC<
	(CommonProps & ButtonProps) | (CommonProps & AnchorProps)
> = (props) => {
	const {
		fullWidth,
		classes,
		children,
		id,
		href,
		as = ButtonTag.Button,
		variant = ButtonVariant.Primary,
		isDisabled = false
	} = props;

	const config = useMemo(() => {
		const omitedProps = Object.fromEntries(
			Object.entries(props).filter(
				(e) =>
					e[0] !== 'fullWidth' &&
					e[0] !== 'classes' &&
					e[0] !== 'href' &&
					e[0] !== 'children' &&
					e[0] !== 'isDisabled' &&
					e[0] !== 'as'
			)
		);
		return {
			...omitedProps,
			className: classNames('button', {'button-full-width': fullWidth}, classes),
		};
	}, [classes, id]);

	// Returned JSX
	switch (as) {
		case ButtonTag.InternalLink:
			const linkConfig = config as any;
			return (
				<Link {...linkConfig} data-variant={variant} to={href ?? '/'}>
					{children}
				</Link>
			);
		case ButtonTag.ExternalLink:
			const anchorConfig = config as AnchorProps;
			return (
				<a {...anchorConfig} data-variant={variant} href={href ?? '/'}>
					{children}
				</a>
			);
		default:
			const buttonConfig = config as ButtonProps;
			return (
				<button data-variant={variant} {...buttonConfig} disabled={isDisabled}>
					{children}
				</button>
			);
	}
};

export default Button;
