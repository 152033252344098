// Action types
import {FiltersActions} from '../actions/filtersActions';

const filtersReducer = (
	state = {},
	action: {type: FiltersActions; payload: {}}
) => {
	switch (action.type) {
		case FiltersActions.SET_ALL_FILTERS:
			return {...state, filters: action.payload};
		case FiltersActions.SET_SEARCH_PHRASE:
			return {...state, searchPhrase: action.payload};
		default:
			return state;
	}
};

export default filtersReducer;
