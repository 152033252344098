import {Link} from 'react-router-dom';
// assets
import {ReactComponent as DocumentSvg} from '../assets/icons/document.svg';
// Types
import {useSelector} from 'react-redux';
import {getUser} from 'redux/selectors';
import {StreamDoc} from 'models/chat';
import {useEffect, useMemo, useState} from 'react';
import Markdown from 'react-markdown';

interface MessageProps {
	// image: string;
	text: string;
	loading?: boolean;
	isUser?: boolean;
	documents?: StreamDoc[];
}

const REGEX_REFERENCE = /reference no. \d+/gi;

const Message = (props: MessageProps) => {
	const {isUser = false, text, documents, loading} = props;
	const user = useSelector(getUser);

	const mateches = useMemo(
		() => Array.from(text.matchAll(REGEX_REFERENCE), (m) => m[0]),
		[text]
	);

	return (
		<div
			className='message message__wrapper'
			data-is-user={isUser}
			data-is-loading={loading}>
			<div className='message__cloud'>
				{loading && text === '' ? (
					<span className='message__loader'>
						<span></span>
						<span></span>
						<span></span>
					</span>
				) : (
					<>
						<span className='message__cloud-text' data-is-loading={loading}>
							<Markdown>{text}</Markdown>
						</span>
						{!!documents?.length && (
							<span className='message__cloud-file'>
								<strong>Source Files:</strong>
								<div className='message__files'>
									{documents.map(
										(doc) =>
											mateches.includes(doc.metadata.reference) && (
												<Link key={doc.metadata.id} to={`/preview/${doc.metadata.id}`}>
													<span>
														<DocumentSvg />
														{doc.metadata.reference}
													</span>
												</Link>
											)
									)}
								</div>
							</span>
						)}
					</>
				)}
			</div>
			{isUser && (
				<div className='message__profile'>{user?.username.slice(0, 1)}</div>
			)}
		</div>
	);
};

export default Message;
