// Libraries
import {FormEvent, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {useLocation, useNavigate, createSearchParams} from 'react-router-dom';
// Components
import Search from 'components/Search';
import Container from 'components/Container';
import Title, {TitleTag, TitleVariant} from 'components/typography/Title';
import DocumentCard from 'components/DocumentCard';
import Filters from 'components/Filters';
import Button from 'components/Button';
// Helpers
import {setQuery} from 'helpers/setQuery';
import {getQueryFilters} from 'helpers/getQyeryFilters';
// Types
import {Tag} from 'types/tags';
import {States, Store} from 'redux/ReduxStore';
import {Document} from 'models/search';
import Loader from 'components/Loader/Loader';

const FILTER_NAMES = {
	STATE: {text: 'State', name: 'state'},
	TYPE: {text: 'Document type', name: 'documentType'},
};

const Results = (props: {documents: Document[] | null}) => {
	const {documents} = props;
	const defaultFilters = useMemo(() => getQueryFilters(), []);
	const [selectedFilters, setSelectedFilters] = useState<any>(defaultFilters);
	const [currentFilters, setCurrentFilters] = useState<any>(defaultFilters);
	const location = useLocation();
	const navigate = useNavigate();

	const {documentType, stateRelevancy} = useMemo(() => {
		const stateArr = documents
			?.map((doc) => doc.metadata.stateRelevancy ?? 'All')
			.filter((item: States | 'All') => item !== 'All');
		const typeArr = documents
			?.map((doc) => doc.metadata.documentType)
			.filter((item) => item !== null);

		return {
			documentType: new Set(typeArr),
			stateRelevancy: new Set(stateArr),
		};
	}, [documents]);

	const filteredResults = useMemo(() => {
		if (!!!Object.keys(currentFilters).length) return documents;
		if (
			!!!Object.keys(currentFilters).find((filter) =>
				Object.keys(currentFilters[filter]).find(
					(key) => currentFilters[filter][key] === true
				)
			)
		)
			return documents;

		const stateFilter = currentFilters[FILTER_NAMES.STATE.name];
		const typeFilters = currentFilters[FILTER_NAMES.TYPE.name];

		return documents?.filter((doc) => {
			if (
				!!stateFilter?.[doc.metadata.stateRelevancy ?? 'All'] ||
				!!typeFilters?.[doc.metadata.documentType]
			)
				return doc;
		});
	}, [currentFilters, documents]);

	const filterSubmitHandler = (e: FormEvent, clear?: boolean) => {
		e.preventDefault();

		if (clear) {
			setCurrentFilters({});
			setSelectedFilters({});
		} else {
			setCurrentFilters(selectedFilters);
		}
		setQuery(navigate, createSearchParams, location, selectedFilters);
	};

	return (
		<Container as={Tag.Section} classes='results'>
			<div className='results__search'>
				<Search />
			</div>
			<div className='results__filter'>
				<form onSubmit={filterSubmitHandler}>
					<div className='results__filter-header'>
						<Title tag={TitleTag.h2} variant={TitleVariant.section}>
							Filter Panel
						</Title>
						<button onClick={(e) => filterSubmitHandler(e, true)}>Clear</button>
					</div>
					<Filters
						filters={[
							{...FILTER_NAMES.STATE, options: stateRelevancy},
							{...FILTER_NAMES.TYPE, options: documentType},
						]}
						currentFilters={selectedFilters}
						setCurrentFilters={setSelectedFilters}
					/>
					<Button>Apply Filter</Button>
				</form>
			</div>
			<div className='results__list'>
				<Title tag={TitleTag.h2} variant={TitleVariant.section}>
					{!!documents
						? `Found ${filteredResults?.length} documents:`
						: 'Loading data...'}
				</Title>
				{!!documents ? (
					filteredResults?.map((doc) => (
						<DocumentCard key={doc.metadata.id} {...doc} isPreview={true} />
					))
				) : (
					<Loader />
				)}
			</div>
		</Container>
	);
};

const mapStateToProps = (state: Store) => {
	return {
		documents: state.documents,
	};
};

export default connect(mapStateToProps)(Results);
