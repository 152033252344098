// Libraries
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
// Components
import ChatListItem from './ChatListItem';
// Selectors
import {chats, getUserToken} from 'redux/selectors';
// APIs
import {getAllSessions} from 'api/chat';
import Loader from './Loader/Loader';

const ChatList = () => {
	const chatsList = useSelector(chats);
	const tokenId = useSelector(getUserToken);

	const [showMore, setShowMore] = useState<boolean | undefined>(undefined);

	useEffect(() => {
		if (!tokenId) return;
		(async () => {
			await getAllSessions(tokenId);
		})();
	}, [tokenId]);

	useEffect(() => {
		if (!chatsList) return;
		setShowMore(chatsList?.length > 10 ? true : undefined);
	}, [chatsList]);

	const visibleList = useMemo(
		() =>
			chatsList && (showMore || showMore === undefined)
				? chatsList
						?.sort(
							(a, b) =>
								new Date(b.created_on).getTime() - new Date(a.created_on).getTime()
						)
						.slice(0, 10)
				: chatsList?.sort(
						(a, b) =>
							new Date(b.created_on).getTime() - new Date(a.created_on).getTime()
				  ),
		[chatsList, showMore]
	);
	// Change Location timezone to australia
	return (
		<ul>
			{visibleList ? (
				visibleList.map(
					(chat) => chat.is_active && <ChatListItem key={chat.id} {...chat} />
				)
			) : (
				<Loader />
			)}
			{showMore !== undefined && (
				<button className='chat__more' onClick={() => setShowMore(!!!showMore)}>
					{showMore ? 'Show more' : 'Show less'}
				</button>
			)}
		</ul>
	);
};

export default ChatList;
