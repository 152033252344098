// Libraries
import classNames from 'classnames';
// Assets
import {ReactComponent as LoaderSvg} from '../../assets/icons/loader.svg';
// Styles
import styles from './Loader.module.scss';

const Loader = () => {
	return (
		<div className={classNames('loader',styles.wrapper)}>
			<LoaderSvg />
		</div>
	);
};

export default Loader;
