// Libraries
import {useState, useEffect} from 'react';
import {useStore} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
// Components
import DocumentCard from 'components/DocumentCard';
import Container from 'components/Container';
import Title, {TitleTag, TitleVariant} from 'components/typography/Title';
import DocumentPreview from 'components/DocumentPreview';
// API
import {postIdSearch} from 'api';
// Types
import {Tag} from 'types/tags';
import {Store} from 'redux/ReduxStore';
import {Document} from 'models/chat';
import Loader from 'components/Loader/Loader';
import { Metadata } from 'models/search';

const Preview = () => {
	const [currentDoc, setCurrentDoc] = useState<Document>();
	const {
		auth: {tokenId},
	} = useStore().getState() as Store;
	const {id} = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		id &&
			tokenId &&
			(async () =>
				await postIdSearch(id, tokenId)
					.then((res) => setCurrentDoc(res.data.value[0]))
					.catch((error) => toast.error(error.message)))();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Container as={Tag.Section} classes='preview'>
			<button
				className='preview__back'
				onClick={() => navigate(-1)}
				aria-label='go back'></button>
			<div className='preview__document'>
				<Title tag={TitleTag.h2} variant={TitleVariant.section}>
					Document information:
				</Title>
				{currentDoc ? (
					<DocumentCard
						page_content={currentDoc.content}
						metadata={{documentType: currentDoc.documentType , stateRelevancy: currentDoc.stateRelevancy, reference: 'Reference'} as Metadata}
						type={currentDoc.documentType}
					/>
				) : (
					<Loader />
				)}
			</div>
			<div className='preview__content'>
				<Title tag={TitleTag.h2} variant={TitleVariant.section}>
					View
				</Title>
				{currentDoc ? <DocumentPreview currentDoc={currentDoc} /> : <Loader />}
			</div>
		</Container>
	);
};

export default Preview;
