// Libraries
import React, {useEffect, useState, ChangeEvent} from 'react';
import classNames from 'classnames';
// Helpers
import validation from '../helpers/validation';

interface InputProps {
	id: string;
	type?: string;
	name: string;
	label: string;
	as?: 'input' | 'textarea';
	required?: boolean;
	value: string;
	errorMessage?: string;
	placeholder?: string;
	isValid?: boolean;
	valueChangeHandler: (name: string, value: any) => void;
	classes?: string;
}

const Input = (props: InputProps) => {
	const {
		id,
		type = 'text',
		name,
		label,
		as = 'input',
		required = false,
		value,
		placeholder,
		errorMessage,
		isValid,
		valueChangeHandler,
		classes,
	} = props;
	const Tag = as;

	const [touched, setTouched] = useState(false);

	const changeHandler = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const errorMessage = validation(touched, e.target.value, name, required);
		valueChangeHandler(name, {
			value: e.target.value,
			errorMessage,
			isValid: errorMessage === '' && touched,
		});
	};

	useEffect(() => {
		const errorMessage = validation(touched, value, name, required);

		valueChangeHandler(name, {
			value,
			errorMessage,
			isValid: errorMessage === '',
		});
	}, [touched]);

	return (
		<div
			className={classNames('input', classes)}
			data-status={!!errorMessage ? 'error' : isValid ? 'success' : ''}>
			<label className='input__label'>
				<span className='input__label-text'>
					{label}
				</span>
				<Tag
					id={id}
					name={name}
					className='input__field'
					type={as === 'textarea' ? undefined : type}
					onChange={changeHandler}
					value={value}
					required={required}
					placeholder={placeholder}
					autoComplete='true'
					onBlur={() => setTouched(true)}
				/>
			</label>
			<span className='input__error'>{errorMessage}</span>
		</div>
	);
};

export default Input;
