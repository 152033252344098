// Libraries
import {FormEvent, useCallback, useEffect, useRef, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Guid} from 'js-guid';
// Components
import Container from 'components/Container';
import Message from 'components/Message';
import Footer from './Footer';
// Icons
import {ReactComponent as Send} from '../assets/icons/send.svg';
import {ReactComponent as MessageSvg} from '../assets/icons/message.svg';
// Selectors
import {
	chats as getChats,
	getAskPhrase,
	getCurrentConversationMessages,
	getCurrentConversationId,
} from 'redux/selectors/chat';
// APIs
import {createSession, getDocuments, getMessage, streamMessage} from 'api/chat';
// Types
import {Tag} from 'types/tags';
import {MessageModel} from 'models/chat';
import {
	clearAskPhrase,
	setAskPhrase,
	setChats,
	setCurrentConversationId,
	setCurrentConversationMessages,
} from 'redux/actions/chatActions';
import {getUserToken} from 'redux/selectors';
import {toast} from 'react-toastify';

const ChatMessages = () => {
	const [loading, setLoading] = useState(true);
	const [message, setMessage] = useState('');
	const [currentStream, setCurrentStream] = useState<string>('');
	const [loadAnswer, setLoadAnswer] = useState(0);
	const [conversation, setConversation] = useState<MessageModel[]>([]);

	const messagesRef = useRef<HTMLDivElement>(null);

	const currentConversationMessages = useSelector(
		getCurrentConversationMessages
	);
	const currentConversationId = useSelector(getCurrentConversationId);
	const askPhrase = useSelector(getAskPhrase);
	const tokenId = useSelector(getUserToken);
	const chats = useSelector(getChats);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {sessionId} = useParams();

	useEffect(() => {
		sessionId && dispatch(setCurrentConversationId(sessionId));
	}, [dispatch, sessionId]);

	const sendQuestion = useCallback(
		async (question: string) => {
			const newHumanMessage: MessageModel = {type: 'human', content: question};
			setConversation([...conversation, newHumanMessage]);
			setMessage('');
			setLoading(true);
			if (!!!sessionId || !!!tokenId) return;
			const messageId = Guid.newGuid();
			const {streamReader}: any = await streamMessage(
				sessionId,
				messageId,
				question,
				tokenId
			);

			let streamPhrase = '';
			while (true) {
				const {done, value} = await streamReader.read();
				if (done) {
					setLoadAnswer(loadAnswer + 1);

					const documents = await getDocuments(sessionId, messageId, tokenId);

					const newAiMessage: MessageModel = {
						type: 'ai',
						content: streamPhrase,
						documents,
					};

					setConversation([...conversation, ...[newHumanMessage, newAiMessage]]);
					setLoading(false);
					setCurrentStream('');
					break;
				}
				streamPhrase += new TextDecoder().decode(value);
				setCurrentStream(streamPhrase);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[conversation, sessionId, tokenId]
	);

	useEffect(() => {
		if (!!!sessionId) return setLoading(false);
		if (sessionId === currentConversationId && !!currentConversationMessages) {
			setLoading(false);
			return setConversation(currentConversationMessages);
		}

		const getMessages = async () =>
			tokenId &&
			(await getMessage(sessionId, tokenId).then((res) => {
				setLoading(false);
				setConversation(res);
			}));

		const sendMessage = async (question: string) => {
			dispatch(clearAskPhrase());
			await sendQuestion(question);
		};

		if (!!askPhrase) {
			sendMessage(askPhrase);
		} else {
			getMessages();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionId, dispatch, tokenId]);

	const submitFormHandler = async (e: FormEvent) => {
		e.preventDefault();
		if (loading) return toast.warning('Generating responses.');
		if (!!!sessionId || message.trim().length === 0) {
			(async () => {
				const response = tokenId && (await createSession(tokenId));

				if (message !== '' && message !== undefined) {
					dispatch(setAskPhrase(message));
				}
				navigate({pathname: `/chat/${response.id}`});
				dispatch(setChats(chats ? [...chats, response] : [response]));
			})();
			return;
		}
		sendQuestion(message);
	};

	useEffect(() => {
		messagesRef.current?.scrollTo(0, messagesRef.current?.scrollHeight);
		dispatch(setCurrentConversationMessages(conversation));
	}, [conversation, dispatch, currentStream]);

	const enterPressHandler = (e: any) => {
		if (
			e.key === 'Enter' &&
			e.shiftKey === false &&
			message.trim().length !== 0
		) {
			e.preventDefault();
			submitFormHandler(e);
		}
	};

	return (
		<Container as={Tag.Section} classes='chat__conversation'>
			<div className='chat__messages' ref={messagesRef}>
				{conversation.length === 0 ? (
					<MessageSvg />
				) : (
					<div>
						{conversation.map((item, index) => (
							<Message
								isUser={item.type === 'human'}
								text={item.content}
								documents={item.documents}
								key={item.type + index}
							/>
						))}
						{loading && (
							<Message loading={true} text={currentStream} isUser={false} />
						)}
					</div>
				)}
				{conversation.length === 0 && (
					<Message
						isUser={false}
						text={
							'This AI tool provides guidance but cannot guarantee accuracy. Your decisions should be based on professional judgement and by checking relevant standards and legislation.'
						}
					/>
				)}
			</div>
			<form className='chat__form' onSubmit={submitFormHandler}>
				<textarea
					name='message'
					id='message'
					placeholder={
						conversation.length === 0
							? 'Start a conversation'
							: 'Continue the conversation...'
					}
					className='chat__form-textarea'
					value={message}
					onChange={(e) => setMessage(e.target.value)}
					onKeyDown={enterPressHandler}></textarea>
				<button
					className='chat__form-button'
					type='submit'
					disabled={loading || message.trim().length === 0}>
					<Send />
				</button>
			</form>
			<Footer />
		</Container>
	);
};

export default ChatMessages;
