import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReduxWrapper from 'redux/ReduxWrapper';
import {PublicClientApplication, EventType} from '@azure/msal-browser';
import {msalConfig} from 'api/msal';
import { MsalProvider } from '@azure/msal-react';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
	!msalInstance.getActiveAccount() &&
	msalInstance.getAllAccounts().length > 0
) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: any) => {
	if (
		(event.eventType === EventType.LOGIN_SUCCESS ||
			event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
			event.eventType === EventType.SSO_SILENT_SUCCESS) &&
		event.payload.account
	) {
		msalInstance.setActiveAccount(event.payload.account);
	}
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<MsalProvider instance={msalInstance}>
		<ReduxWrapper>
			<App />
		</ReduxWrapper>
	</MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
