// Libraries
import {ChangeEvent} from 'react';
import classNames from 'classnames';

interface CheckboxProps {
	id: string;
	name: string;
	label: string | React.ReactNode;
	type?: 'checkbox' | 'radio';
	value: boolean;
	valueChangeHandler: (name: string, value: any) => void;
	classes?: string;
}

const Checkbox = (props: CheckboxProps) => {
	const {
		id,
		name,
		label,
		type = 'checkbox',
		value,
		valueChangeHandler,
		classes,
	} = props;

	const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		valueChangeHandler(name, {
			value: e.target.checked,
		});
	};

	return (
		<label
			className={classNames(
				{checkbox: type === 'checkbox', radio: type === 'radio'},
				classes
			)}
			data-is-radio={type === 'radio'}>
			<input
				id={id}
				name={name}
				className='checkbox__field'
				type={type}
				onChange={changeHandler}
				checked={value}
			/>
			<span className='checkbox__label'>{label}</span>
		</label>
	);
};

export default Checkbox;
