// Librariesa
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
// Components
import Button from 'components/Button';
import ChatList from 'components/ChatList';
import ChatMessages from 'components/ChatMessages';
// Icons
import {ReactComponent as AddMessageSvg} from '../assets/icons/add-chat.svg';
import {createSession} from 'api/chat';
import {useSelector} from 'react-redux';
import {getUserToken} from 'redux/selectors';
import {addChat} from 'redux/actions/chatActions';
import {useState} from 'react';

const Chat = () => {
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const tokenId = useSelector(getUserToken);

	const newTopicHandler = async () => {
		setIsLoading(true);
		const response = tokenId && (await createSession(tokenId));
		navigate(`/chat/${response.id}`);
		dispatch(addChat(response));
		setIsLoading(false);
	};

	return (
		<div className='chat'>
			<aside className='chat__list'>
				<Button
					classes='chat__list-btn'
					onClick={newTopicHandler}
					isDisabled={isLoading}>
					<AddMessageSvg /> New topic
				</Button>
				<h2 className='chat__list-title'>Historical conversations:</h2>
				<ChatList />
			</aside>
			<ChatMessages />
		</div>
	);
};

export default Chat;
