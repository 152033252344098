import {Configuration} from './config.types';

const configuration: Configuration = require('./config.json');

let config = {} as Configuration;

try {
	const overrideConfig = require('./config.override.json');
	config = Object.assign(configuration, overrideConfig);
} catch (ex) {
	config = configuration;
}

export {config};
