// Libraries
import {useEffect} from 'react';
import {BrowserRouter as Router, Routes} from 'react-router-dom';
import {
	MsalAuthenticationTemplate,
	UnauthenticatedTemplate,
	useMsal,
} from '@azure/msal-react';
import {EventType, InteractionType} from '@azure/msal-browser';
import {Route} from 'react-router-dom';
// Components
import Layout from 'components/layout/Layout';
// Pages
import Login from 'pages/Login';
import Register from 'pages/Register';
import Home from 'pages/Home';
import Results from 'pages/Results';
import Preview from 'pages/Preview';
import Chat from 'pages/Chat';
// Styles
import './scss/main.scss';
import {b2cPolicies, loginRequest} from 'api/msal';
import {compareIssuingPolicy} from 'helpers/claimUtils';
import {useDispatch, useSelector} from 'react-redux';
import {
	setDefaultPersona,
	setDefaultState,
	setUser,
	setUserToken,
} from 'redux/actions/authActions';
import {getUser} from 'redux/selectors';
import {States} from 'redux/ReduxStore';

function App() {
	const {instance, accounts, inProgress} = useMsal();

	const dispatch = useDispatch();
	const user = useSelector(getUser);

	useEffect(() => {
		if (!!user) return;
		accounts[0] && dispatch(setUser(accounts[0]));
	}, [dispatch, accounts, user]);

	useEffect(() => {
		const callbackId = instance.addEventCallback((event: any) => {
			if (
				(event.eventType === EventType.LOGIN_SUCCESS ||
					event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
				event.payload.account
			) {
				/**
				 * For the purpose of setting an active account for UI update, we want to consider only the auth
				 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
				 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
				 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
				 */
				if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
					// retrieve the account from initial sing-in to the app
					const originalSignInAccount = instance
						.getAllAccounts()
						.find(
							(account: any) =>
								account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
								account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
								account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn
						);

					let signUpSignInFlowRequest = {
						authority: b2cPolicies.authorities.signUpSignIn.authority,
						account: originalSignInAccount,
					};

					// silently login again with the signUpSignIn policy
					instance.ssoSilent(signUpSignInFlowRequest);
				}

				/**
				 * Below we are checking if the user is returning from the reset password flow.
				 * If so, we will ask the user to reauthenticate with their new password.
				 * If you do not want this behavior and prefer your users to stay signed in instead,
				 * you can replace the code below with the same pattern used for handling the return from
				 * profile edit flow
				 */
				if (
					compareIssuingPolicy(
						event.payload.idTokenClaims,
						b2cPolicies.names.forgotPassword
					)
				) {
					let signUpSignInFlowRequest: any = {
						authority: b2cPolicies.authorities.signUpSignIn.authority,
					};
					instance.loginRedirect(signUpSignInFlowRequest);
				}
			}

			if (event.eventType === EventType.LOGIN_FAILURE) {
				// Check for forgot password error
				// Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
				if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
					const resetPasswordRequest = {
						authority: b2cPolicies.authorities.forgotPassword.authority,
						scopes: [],
					};
					instance.loginRedirect(resetPasswordRequest);
				}
			}
		});

		return () => {
			if (callbackId) {
				instance.removeEventCallback(callbackId);
			}
		};
	}, [instance]);

	useEffect(() => {
		if (inProgress !== 'none') return;
		(async () =>
			await instance
				.acquireTokenSilent({
					scopes: ['openid'],
					forceRefresh: true,
					refreshTokenExpirationOffsetSeconds: 7200,
				})
				.then((response) => {
					dispatch(
						setDefaultPersona(
							(response.idTokenClaims as any).extension_Personapreference
						)
					);
					dispatch(setDefaultState((response.idTokenClaims as any).state as States));
					dispatch(setUserToken(response.idToken));
					const activeAccount = instance.getActiveAccount();
					activeAccount && dispatch(setUser(activeAccount));
				})
				.catch((error) => {}))();
	}, [dispatch, instance, inProgress]);

	return (
		<Router>
			<Layout>
				<Routes>
					<Route
						path='/login'
						element={
							<UnauthenticatedTemplate>
								<Login />
							</UnauthenticatedTemplate>
						}
					/>
					<Route
						path='/register'
						element={
							<UnauthenticatedTemplate>
								<Register />
							</UnauthenticatedTemplate>
						}
					/>

					<Route
						path='/'
						element={
							<MsalAuthenticationTemplate
								interactionType={InteractionType.Redirect}
								authenticationRequest={loginRequest}>
								<Home />
							</MsalAuthenticationTemplate>
						}
					/>
					<Route
						path='/results'
						element={
							<MsalAuthenticationTemplate
								interactionType={InteractionType.Redirect}
								authenticationRequest={loginRequest}>
								<Results />
							</MsalAuthenticationTemplate>
						}
					/>
					<Route
						path='/preview/:id'
						element={
							<MsalAuthenticationTemplate
								interactionType={InteractionType.Redirect}
								authenticationRequest={loginRequest}>
								<Preview />
							</MsalAuthenticationTemplate>
						}
					/>
					<Route
						path='/chat'
						element={
							<MsalAuthenticationTemplate
								interactionType={InteractionType.Redirect}
								authenticationRequest={loginRequest}>
								<Chat />
							</MsalAuthenticationTemplate>
						}
					/>
					<Route
						path='/chat/:sessionId'
						element={
							<MsalAuthenticationTemplate
								interactionType={InteractionType.Redirect}
								authenticationRequest={loginRequest}>
								<Chat />
							</MsalAuthenticationTemplate>
						}
					/>
				</Routes>
			</Layout>
		</Router>
	);
}

export default App;
