// Libraries
import {useState, useRef, useEffect} from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
// Types
import {Document} from 'models/chat';
import Loader from './Loader/Loader';

interface DocumentPreviewProps {
	currentDoc: Document;
	isPDF?: boolean;
}

const Hocr = (props: DocumentPreviewProps) => {
	const {currentDoc} = props;
	const [zoom, setZoom] = useState(1);
	const [baseWidth, setBaseWidth] = useState(0);
	const [baseHeight, setBaseHeight] = useState(0);

	const docRef = useRef<any>(null);

	useEffect(() => {
		setBaseHeight(docRef.current.clientHeight);
		setBaseWidth(docRef.current.clientWidth);
	}, [docRef]);

	const increaseScale = () => {
		const newScale = zoom + 0.15;

		return setZoom(newScale >= 2 ? 2 : newScale);
	};
	const decreaseScale = () => {
		const newScale = zoom - 0.15;

		return setZoom(newScale <= 0.3 ? 0.3 : newScale);
	};

	return (
		<div className='document-preview'>
			<div className='document-preview__options'>
				<button className='document-preview__button' onClick={() => setZoom(1)}>
					1:1
				</button>
				<button className='document-preview__button' onClick={increaseScale}>
					+
				</button>
				<button className='document-preview__button' onClick={decreaseScale}>
					-
				</button>
			</div>
			<div className='document-preview__text' ref={docRef}>
					{currentDoc.content ? (
						<MarkdownPreview
							source={currentDoc.content}
							style={{
								transform: `translateY(${
									zoom > 1 ? baseHeight * zoom - baseHeight : 0
								}px) translateX(${
									zoom > 1 ? baseWidth * zoom - baseWidth : 0
								}px) scale(${zoom})`,
								height: baseHeight !== 0 ? baseHeight * zoom : 'auto',
								width: baseWidth !== 0 ? baseWidth * zoom : 'max-content',
							}}
							wrapperElement={{
								'data-color-mode': 'light',
							}}
						/>
					) : (
						<Loader />
					)}
			</div>
		</div>
	);
};

export default Hocr;
