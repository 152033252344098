// Libraries
import {useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
// Components
import Info from './Info';
import Checkbox from './Checkbox';
import Modal from './Modal';
// Actions
import {setPersona, setState} from 'redux/actions/authActions';
import {Title, Text} from './typography';
// Selectors
import {
	getDefaultPersona,
	getDefaultState,
	getPersona,
	getState,
} from 'redux/selectors/auth';
import {getCurrentConversationMessages} from 'redux/selectors/chat';
// Types
import {States, Personas} from 'redux/ReduxStore';
import {TitleTag, TitleVariant} from './typography/Title';

const STATES = [
	{
		id: States.AustralianCapitalTerritory,
		name: 'Australian Capital Territory (ACT)',
	},
	{id: States.NewSouthWales, name: 'New South Wales (NSW)'},
	{id: States.NorthernTerritory, name: 'Northern Territory (NT)'},
	{id: States.SouthAustralia, name: 'South Australia (SA)'},
	{id: States.Tasmania, name: 'Tasmania (TAS)'},
	{id: States.Victoria, name: 'Victoria (VIC)'},
	{id: States.WesternAustralia, name: 'Western Australia (WA)'},
];

const TAILORED = [
	{id: Personas.Apprentices, name: 'Apprentice'},
	{id: Personas.Electricians, name: 'Electrician'},
	{id: Personas.ElectricalDesign, name: 'Electrical Designer/Estimator'},
	{id: Personas.ElectricalEngineers, name: 'Electrical Engineer'},
	{id: Personas.ElectriciansInspectors, name: 'Electrical Inspector'},
];

const Footer = () => {
	const [modal, setModal] = useState(false);

	const dispatch = useDispatch();
	const state = useSelector(getState);
	const defaultState = useSelector(getDefaultState);
	const persona = useSelector(getPersona);
	const defaultPersona = useSelector(getDefaultPersona);
	const currentConversationMessages = useSelector(
		getCurrentConversationMessages
	);

	const currentState = useMemo(
		() => state ?? defaultState,
		[state, defaultState]
	);
	const currentPersona = useMemo(
		() => persona ?? defaultPersona,
		[persona, defaultPersona]
	);

	const ongoingConversation = useMemo(
		() => !!currentConversationMessages?.length,
		[currentConversationMessages]
	);

	const stateName = useMemo(
		() =>
			STATES.find(({id}) => id === currentState)?.name.replace(/\([^)]*\)/, ''),
		[currentState]
	);
	const tailoredName = useMemo(
		() => TAILORED.find(({id}) => id === currentPersona),
		[currentPersona]
	);

	return (
		<>
			<footer className='footer'>
				<div>
					<p>Answers are based on legislation in the following state:</p>{' '}
					<Info
						hoverItem={
							<>
								<span>{stateName}</span>
							</>
						}>
						{STATES.map((item) => (
							<Checkbox
								classes='footer__radio'
								key={item.id}
								type='radio'
								id={item.id}
								name={'state'}
								value={currentState === item.id}
								valueChangeHandler={() =>
									ongoingConversation ? setModal(true) : dispatch(setState(item.id))
								}
								label={item.name}
							/>
						))}
					</Info>
				</div>
				<div>
					<p>Style of answer tailored to:</p>{' '}
					<Info
						hoverItem={
							<>
								<span>{tailoredName?.name}</span>
							</>
						}>
						{TAILORED.map((item) => (
							<Checkbox
								classes='footer__radio'
								key={item.id}
								type='radio'
								id={item.id}
								name={'tailored'}
								value={tailoredName?.id === item.id}
								valueChangeHandler={() =>
									ongoingConversation ? setModal(true) : dispatch(setPersona(item.id))
								}
								label={item.name}
							/>
						))}
					</Info>
				</div>
				<small className='footer__info'>
					AI powered applications may not always be 100% accurate. Always cross-check
					with applicable standards and legislation.
				</small>
			</footer>
			{modal && (
				<Modal
					closeHandler={() => setModal(false)}
					wrapperClasses={'footer__modal'}>
					<Title tag={TitleTag.h3} variant={TitleVariant.section}>
						Change impossible!
					</Title>
					<Text>
						If you want to change the style or state, you have to create new one
						conversation.
					</Text>
				</Modal>
			)}
		</>
	);
};

export default Footer;
