import {AccountInfo} from '@azure/msal-browser';
import {States, Personas} from 'redux/ReduxStore';

export enum AuthActions {
	SET_DEFAULT_STATE = 'SET_DEFAULT_STATE',
	SET_DEFAULT_PERSONA = 'SET_DEFAULT_PERSONA',
	SET_STATE = 'SET_STATE',
	SET_PERSONA = 'SET_PERSONA',
	SET_USER = 'SET_USER',
	SET_USER_TOKEN = 'SET_USER_TOKEN',
}

export const setDefaultState = (state: States) => ({
	type: AuthActions.SET_DEFAULT_STATE,
	payload: state,
});

export const setDefaultPersona = (state: Personas) => ({
	type: AuthActions.SET_DEFAULT_PERSONA,
	payload: state,
});

export const setState = (state: States) => ({
	type: AuthActions.SET_STATE,
	payload: state,
});

export const setPersona = (state: Personas) => ({
	type: AuthActions.SET_PERSONA,
	payload: state,
});

export const setUser = (user: AccountInfo) => ({
	type: AuthActions.SET_USER,
	payload: user,
});

export const clearUser = () => ({
	type: AuthActions.SET_USER,
	payload: undefined,
});

export const setUserToken = (token: string) => ({
	type: AuthActions.SET_USER_TOKEN,
	payload: token,
});

export const clearUserToken = () => ({
	type: AuthActions.SET_USER_TOKEN,
	payload: undefined,
});
