export const cutText = (text: string, removeFrom: string, forward?: boolean) => {
	const index = text.indexOf(removeFrom);

	if (index !== -1) {
		const resultString = text.substring(0, index);
		if(forward) return text.replace(resultString, '')
		return resultString;
	} else {
		return text;
	}
};
