import React from 'react';
import classNames from 'classnames';
// Types
import {Common} from '../../types/main';

interface TextProps extends Common {
	as?: 'p' | 'span';
}

const Text = ({children, classes, as: Tag = 'p'}: TextProps) => (
	<Tag className={classNames('text', classes)}>{children}</Tag>
);

export default Text;
