// Libraries
import classNames from 'classnames';
import ReactSelect from 'react-select';

interface SelectProps {
	id: string;
	name: string;
	options: any;
	value: {label: string, value: string};
	label: string;
	valueChangeHandler: (name: string, value: any) => void;
	classes?: string;
}

const Select = (props: SelectProps) => {
	const {options, value, classes, label, valueChangeHandler, name} = props;

	return (
		<div className={classNames('input select', classes)}>
			<label className='input__label'>
				<span className='input__label-text'>{label}</span>
				<ReactSelect
					options={options}
					className='select'
					value={value}
					getOptionLabel={(option: any) => option?.label}
					onChange={(newValue: any) => {
						valueChangeHandler(name, {value: newValue, errorMessage: '', isValid: true});
					}}
				/>
			</label>
		</div>
	);
};

export default Select;
