// Types
import { ChatModel, MessageModel } from "models/chat";

export enum ChatActions {
  SET_CHATS = 'SET_CHATS',
  ADD_CHAT = 'ADD_CHAT',
  REMOVE_CHAT = 'REMOVE_CHAT',
  SET_ASK_PHRASE = 'SET_ASK_PHRASE',
  SET_CURRENT_CONVERSATION_MESSAGES = 'SET_CURRENT_CONVERSATION_MESSAGES',
  SET_CURRENT_CONVERSATION_ID = 'SET_CURRENT_CONVERSATION_ID',
}

export const setChats = (chats: ChatModel[]) => ({
  type: ChatActions.SET_CHATS,
  payload: chats
})

export const addChat = (chats: ChatModel) => ({
  type: ChatActions.ADD_CHAT,
  payload: chats
})

export const removeChat = (id: string) => ({
  type: ChatActions.REMOVE_CHAT,
  payload: id
})

export const setAskPhrase = (phrase: string) => ({
  type: ChatActions.SET_ASK_PHRASE,
  payload: phrase
})

export const clearAskPhrase = () => ({
  type: ChatActions.SET_ASK_PHRASE,
  payload: undefined
})

export const setCurrentConversationMessages = (conversation: MessageModel[]) => ({
  type: ChatActions.SET_CURRENT_CONVERSATION_MESSAGES,
  payload: conversation
})

export const setCurrentConversationId = (id: string) => ({
  type: ChatActions.SET_CURRENT_CONVERSATION_ID,
  payload: id
})