import {useEffect} from 'react';
// Components
import {Title} from 'components/typography';
import ContentWraper from 'components/ContentWraper';
// Types
import {TitleTag, TitleVariant} from 'components/typography/Title';
import {Tag} from 'types/tags';
import {useMsal} from '@azure/msal-react';
import Loader from 'components/Loader/Loader';

const Login = () => {
	const {instance, accounts} = useMsal();

	const handleCustomLogin = async () => {
		try {
			await instance.loginRedirect({
				scopes: ['openid', 'profile'],
			});
		} catch (error) {
			console.error('Login failed:', error);
		}
	};

	useEffect(() => {
		!!!accounts.length && handleCustomLogin();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ContentWraper as={Tag.Section} classes='login-panel'>
			<Title
				tag={TitleTag.h2}
				variant={TitleVariant.main}
				classes='home-search__form-title'>
				Log in redirection
			</Title>
			<Loader />
		</ContentWraper>
	);
};

export default Login;
