// Libraries
import {FormEvent, useEffect, useMemo, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useSelector, useStore} from 'react-redux';
import {toast} from 'react-toastify';
// Actions
import {setDocuments} from 'redux/actions/documentsActions';
import {setSearchPhrase} from 'redux/actions/filtersActions';
// Assets
import {ReactComponent as Magnifier} from 'assets/icons/search-icon.svg';
// API
import { getDocument } from 'api/search';
import {createSession} from 'api/chat';
// Types
import {Store} from 'redux/ReduxStore';
import {cutText} from 'helpers/cutText';
import {setAskPhrase} from 'redux/actions/chatActions';
import {getUserToken} from 'redux/selectors';

interface SearchProps {
	isChat?: boolean;
}

const Search = (props: SearchProps) => {
	const {isChat} = props;
	const [isBlocked, setIsBlocked] = useState(false);
	const tokenId = useSelector(getUserToken);

	const navigate = useNavigate();
	const location = useLocation();
	const defaultSearch = useMemo(
		() =>
			cutText(location.search.replace('?search=', ''), '?filters=').replaceAll(
				'%20',
				' '
			),
		[location]
	);
	const {dispatch, getState} = useStore();
	const {
		documents,
		auth: {state},
	} = getState() as Store;

	const [search, setSearch] = useState(defaultSearch);

	const submitHandler = async (e: FormEvent) => {
		e.preventDefault();
		setIsBlocked(true);
		if (isChat) {
			const response = tokenId && (await createSession(tokenId));
			if (search !== '') {
				dispatch(setAskPhrase(search));
			}
			navigate({pathname: `/chat/${response.id}`});
		} else {
			let sendRequest = location.pathname.includes('results');
			documents !== null && dispatch(setDocuments(null));
			navigate({pathname: '/results', search: `?search=${search}`});
			sendRequest &&
				tokenId &&
				(await getDocument(search, tokenId, state)
					.then((data) => dispatch(setDocuments(data)))
					.catch((error) => toast.error(error.message)));
		}
		setIsBlocked(false);
	};

	useEffect(() => {
		setSearch(defaultSearch);
		dispatch(setSearchPhrase(defaultSearch));
	}, [defaultSearch, dispatch]);

	useEffect(() => {
		if (
			(defaultSearch === search && (documents?.length ?? 0) > 0) ||
			!location.pathname.includes('results')
		)
			return;
		(async () =>
			tokenId &&
			(await getDocument(defaultSearch, tokenId, state)
				.then((data) => dispatch(setDocuments(data)))
				.catch((error) => toast.error(error.message))))();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<form onSubmit={submitHandler} className='search'>
			<button type='submit' className='search__magnifier'>
				<Magnifier />
			</button>
			<input
				className='search__input'
				type='text'
				name='search'
				id='search'
				placeholder={
					isChat
						? 'Start a Conversation'
						: 'Search through standards and legislations'
				}
				value={search}
				onChange={(e) => setSearch(e.target.value)}
			/>
			<button className='search__submit' type='submit' disabled={isBlocked}>
				{isChat ? 'Ask me anything' : 'Search'}
			</button>
		</form>
	);
};

export default Search;
