import {NavigateFunction, Location} from 'react-router-dom';
import {cutText} from './cutText';

export const setQuery = (
	navigate: NavigateFunction,
	createSearchParams: any,
	location: Location<any>,
	queryObject: any
) => {
	const search = cutText(
		location.search.replace('?search=', ''),
		'?filters='
	).replaceAll('%20', ' ');
	let query: any = {};
	Object.keys(queryObject).map((key) => {
		if (key.includes('search')) return;
		const values = Object.keys(queryObject[key]).filter(
			(filter) => queryObject[key][filter]
		);
		if (!values.length) return;
		query[key] = values.toString();
	});

	if (!!!Object.keys(query).length)
		return navigate({search: `?search=${search}`});
	const filters = createSearchParams(query);

	return navigate({search: `?search=${search}?filters=${filters}`});
};
