// Libraries
import {createStore, compose, combineReducers} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// Reducers
import authReducer from './reducers/authReducer';
import filtersReducer from './reducers/filtersReducer';
import documentsReducer from './reducers/documentsReducer';
import chatReducer from './reducers/chatReducer';
// Types
import {Document} from 'models/search';
import {ChatModel, MessageModel} from 'models/chat';
import {AccountInfo} from '@azure/msal-browser';

export enum States {
	AustralianCapitalTerritory = 'Australian Capital Territory',
	NewSouthWales = 'New South Wales',
	NorthernTerritory = 'Northern Territory',
	SouthAustralia = 'South Australia',
	Tasmania = 'Tasmania',
	Victoria = 'Victoria',
	WesternAustralia = 'Western Australia',
}

export enum Personas {
	Electricians = 'electrician',
	ElectriciansInspectors = 'electrician-inspector',
	ElectricalEngineers = 'electrical-engineer',
	ElectricalDesign = 'electrical-designer',
	EstimationSpecialists = 'estimation-specialist',
	Apprentices = 'apprentice',
}

export interface ChatsState {
	askPhrase?: string;
	conversation: ChatModel[];
	currentConversation: {id?: string; messages: MessageModel[] | undefined};
}
export interface AuthState {
	user?: AccountInfo;
	defaultState: States;
	defaultPersona: Personas;
	state: States;
	persona: Personas;
	tokenId?: string;
}
export interface Store {
	auth: AuthState;
	documents: Document[] | null;
	searchPhrase?: string;
	filters: {searchPhrase?: string};
	chats: ChatsState;
}

export const initialState: Store = {
	auth: {
		user: undefined,
		defaultState: States.Victoria,
		state: States.Victoria,
		defaultPersona: Personas.Electricians,
		persona: Personas.Electricians,
	},
	documents: null,
	searchPhrase: undefined,
	filters: {},
	chats: {
		askPhrase: undefined,
		conversation: [],
		currentConversation: {messages: []},
	},
};

const composeEnhancers =
	(process.env.NODE_ENV === 'development' &&
		typeof window !== 'undefined' &&
		(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
	compose;

const persistConfig = {
	key: 'root',
	storage,
};

const combinedReducers = combineReducers({
	auth: authReducer,
	documents: documentsReducer,
	filters: filtersReducer,
	chats: chatReducer,
});

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = createStore(persistedReducer, composeEnhancers());
export const persistor = persistStore(store);
