// Action types
import {ChatActions} from '../actions/chatActions';
import {ChatsState} from 'redux/ReduxStore';

const chatReducer = (
	state: ChatsState = {
		askPhrase: undefined,
		conversation: [],
		currentConversation: {messages: []},
	},
	action: {type: ChatActions; payload: any}
): ChatsState => {
	switch (action.type) {
		case ChatActions.SET_CHATS:
			return {
				...state,
				conversation: action.payload,
			};
		case ChatActions.ADD_CHAT:
			return {
				...state,
				conversation: [...state.conversation, action.payload],
			};
		case ChatActions.REMOVE_CHAT:
			return {
				...state,
				conversation: state.conversation?.filter(
					(chat) => chat.id !== action.payload
				),
			};
		case ChatActions.SET_CURRENT_CONVERSATION_MESSAGES:
			return {
				...state,
				currentConversation: {
					...state.currentConversation,
					messages: action.payload,
				},
			};
		case ChatActions.SET_CURRENT_CONVERSATION_ID:
			return {
				...state,
				currentConversation: {
					...state.currentConversation,
					id: action.payload,
				},
			};
		case ChatActions.SET_ASK_PHRASE:
			return {...state, askPhrase: action.payload};
		default:
			return state;
	}
};

export default chatReducer;
